import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
    Typography,
    Button,
    Box,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    Paper,
    Switch,
    Link,
    TablePagination,
    TableSortLabel,
    IconButton,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DialogBox } from '../../../common/Dialog/DialogBox';
import { TooltipText } from '../../../components/inputComponents/TooltipText';
import { Notification } from '../../../common/Notification/Notification';
import { UploadPrizesModal } from '../../../components/modals/UploadPrizesModal';
import {
    updatePrizeCatalogueTable,
    deletePrizeCatalogueTable,
} from '../../../graphql/mutations';
import { defaultNotifState } from '../../../constants/currency-constants';
import { prizesRef } from '../../../constants/helpful-link';
import { getPrizeForConfig } from '../../../graphql/queries';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GetPrizeForConfigResult } from "../../../types/componentTypes/PrizeTypes";

import './Prizes.css';
import '../CommonStyles.css';
import '../../../components/inputComponents/SwitchComponent.css';
import { CopyPrizeModal } from '../../../components/modals/CopyPrizeModal';
import { getPrizesForConfig } from '../../../graphql/queries';

export interface prizeData {
    priority: number;
    name: string;
    tier: number;
    prize_id: string;
    active: boolean;
    configuration_id: string;
    cost?: any;
}

interface PrizesProps {
    config: { [key: string]: any };
    prizesData: Array<prizeData>;
    setPrizeData: (value: Array<prizeData>) => void;
}

interface CostObject {
    amount: number;
    currency_id: string;
    name: string;
}

const availableTooltipText = `If vouchers were uploaded for this prize,
it shows the corresponding number of available vouchers out of the total quantity.
If voucher distribution is not required,
it shows both available and total quantities that were set in the prize configuration wizard`;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof prizeData>(
    order: Order,
    orderBy: Key,
): (
    a: prizeData,
    b: prizeData,
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const Prizes = ({ config, prizesData, setPrizeData }: PrizesProps) => {
    const [modalOpenState, setModalOpenState] = useState(false);
    const [copyPrizeModalOpenState, setCopyPrizeModalOpenState] = useState(false);
    const [copiedPrize, setCopiedPrize] = useState();
    const [copyPrizeEnabled, setCopyPrizeEnabled] = useState(false);

    const [prizes, setPrizes] = useState<Array<prizeData>>([]);
    const [prizeLimits, setPrizeLimits] = useState<any>({});
    const [defaultLanguage, setDefaultLanguage] = useState('');
    const [collectAndGetPrizes, setCollectAndGetPrizes] = useState(false);
    const [confirmPrizeDeactivate, setConfirmPrizeDeactivate] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedPrizeId, setSelectedPrizeId] = useState<string>('');
    const [page, setPage] = useState(0);
    const [notificationState, setNotificationState] = useState(defaultNotifState);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof prizeData>('name');

    const handleRequestSort = (property: keyof prizeData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDeactivationDialogueClose = () => {
        setConfirmPrizeDeactivate(false);
    };

    const getSelectedPrize = () => {
        return prizes.filter(
            (item: prizeData) => item.prize_id === selectedPrizeId
        )[0];
    };

    const handlePrizeDeactivation = async () => {
        const prize: prizeData = getSelectedPrize();
        try {
            const res: any = await API.graphql({
                query: updatePrizeCatalogueTable, variables: {
                    input: {
                        configuration_id: prize.configuration_id,
                        prize_id: prize.prize_id,
                        active: !prize.active,
                    },
                },
            });
            prize.active = res.data?.updatePrizeCatalogueTable?.active;
            setConfirmPrizeDeactivate(false);
            setNotificationState({
                open: true,
                title: 'Prize updated successfully!',
                content: `Status changed to ${(prize.active && 'active') || 'deactivated'
                    }`,
                level: 'success',
            });
        } catch (e) {
            setNotificationState({
                open: true,
                title: 'Prize update request failed!',
                content: '',
                level: 'error',
            });
            console.error('Prize update failed with: ', e);
        }
    };

    const handleDeleteDialogueClose = () => {
        setConfirmDelete(false);
    };

    const handlePrizeDeletion = async () => {
        const selectedPrize: prizeData = getSelectedPrize();
        try {
            await API.graphql({
                query: deletePrizeCatalogueTable,
                variables: {
                    input: {
                        configuration_id: selectedPrize.configuration_id,
                        prize_id: selectedPrize.prize_id,
                    },
                },
            });
            const updatedPrizesArr = prizes.filter(
                (prize: prizeData) => prize.prize_id !== selectedPrize.prize_id
            );
            setPrizes(updatedPrizesArr);
            setPrizeData(updatedPrizesArr);
            setConfirmDelete(false);
            setNotificationState({
                open: true,
                title: 'Prize deleted successfully!',
                content: `${selectedPrize.prize_id} was deleted!`,
                level: 'success',
            });
        } catch (e) {
            setNotificationState({
                open: true,
                title: 'Prize delete request failed!',
                content: '',
                level: 'error',
            });
            console.error('Prize deletion failed with: ', e);
        }
    };

    const togglePrizeActiveButtonHandlers = [
        handleDeactivationDialogueClose,
        handlePrizeDeactivation,
    ];
    const deletePrizeButtonHandlers = [
        handleDeleteDialogueClose,
        handlePrizeDeletion,
    ];

    useEffect(() => {
        setPrizes(prizesData);
        if (prizesData?.[0]?.cost) {
            setCollectAndGetPrizes(true);
        }
    }, [prizesData, prizesData?.length]);

    useEffect(() => {
        const configPrizeLimits =
            config?.flow?.instantWin?.params?.winningLimitsPerPrize;
        configPrizeLimits && setPrizeLimits(configPrizeLimits);
        setDefaultLanguage(config?.configurationParameters?.language);
    }, [config]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const processPrizeCost = (costArrayInput: string) => {
        return JSON.parse(costArrayInput).map(
            (cost: CostObject) =>
                `${cost.amount} ${cost.amount > 1 ? cost.name + `s` : cost.name}`
        );
    };

    const getPrizes = async () => {
        const queryResult: any = await API.graphql({
            query: getPrizesForConfig,
            variables: { configuration_id: config.configurationId },
        });
        const prizesForConfig = queryResult?.data?.getPrizesForConfig?.items || [];
        setPrizes(prizesForConfig);
    };

    const openCopyPrizeModal = (prize) => {
        setCopyPrizeModalOpenState(true);
        setCopiedPrize(prize);
        setSelectedPrizeId(prize.prize_id);
    }

    const closeCopyPrizeModal = async () => {
        setCopyPrizeModalOpenState(false);
        setCopyPrizeEnabled(false);
        setSelectedPrizeId(null);
        await getPrizes();
    }

    const closeUploadPrizesModal = async () => {
        setModalOpenState(false);
        await getPrizes();
    }

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
        setNotificationState({
            open: true,
            title: 'Prize Id Copied!',
            content: '',
            level: 'success',
        });
    };

    const getMechanic = () => {
        if (config?.flow?.autoRedeemCnG) return 'autoRedeemCnG';
        return config?.flow?.redeemPincodeForCurrencies ? 'c&g' : 'iw';
    }

    const getPrizeType = (prize) => {
        if (prize.cost) return prize.auto_redeem ? 'Auto Collect n Get' : 'Collect n Get';
        return prize.pool_prize ? 'Always Win' : 'Instant Win';
    }

    const fetchUpdatedPrizes = async () => {
        const updatedPrizes = [];
        for (const prize of prizes) {
            const updatedPrize = await API.graphql(
                graphqlOperation(getPrizeForConfig, { configuration_id: config.configurationId, prize_id: prize.prize_id })
            ) as GraphQLResult<GetPrizeForConfigResult>
            updatedPrizes.push(updatedPrize.data.getPrizeForConfig);
        }
        setPrizes(updatedPrizes);
    };

    return (
        <>
            <UploadPrizesModal
                header='Add or Update Prizes via Bulk Upload'
                modalOpenState={modalOpenState}
                handleClose={closeUploadPrizesModal}
                configId={config.configurationId}
                refreshData={fetchUpdatedPrizes}
            />
            <CopyPrizeModal
                header='Prize Dates'
                modalOpenState={copyPrizeModalOpenState}
                handleClose={closeCopyPrizeModal}
                configPrizes={prizes}
                copiedPrize={copiedPrize}
                defaultLanguage={defaultLanguage}
                ratioWinningEnabled={config.flow.instantWin?.params?.ratioWinning}
            />
            <DialogBox
                isOpen={confirmPrizeDeactivate}
                timeoutBeforeSubmit={false}
                actionButtonLabels={['Close', 'Yes']}
                actionButtonHandlers={togglePrizeActiveButtonHandlers}
                dialogCloseHandler={handleDeactivationDialogueClose}
                dialogContent={
                    'Are you sure that you want to update the prize status ?'
                }
            />
            <DialogBox
                isOpen={confirmDelete}
                timeoutBeforeSubmit={false}
                actionButtonLabels={['Close', 'Yes']}
                actionButtonHandlers={deletePrizeButtonHandlers}
                dialogCloseHandler={handleDeleteDialogueClose}
                dialogContent={'Are you sure that you want to delete the prize?'}
            />

            <Notification
                notificationState={notificationState}
                setNotificationState={setNotificationState}
            />
            <Box className='prizesWrapper'>
                <Box className='tabHeader'>
                    <Typography variant='h3'>Prizes</Typography>
                    <Button
                        disabled={copyPrizeEnabled}
                        variant='contained'
                        color='primary'
                        type='submit'
                        className='firstHeaderButton'
                        component={RouterLink}
                        to={`/addPrize/${config.promotionId}/${config.configurationId}/`}
                    >
                        + Add Prize
                    </Button>
                    <Button
                        disabled={copyPrizeEnabled}
                        variant='outlined'
                        color='primary'
                        onClick={() => setModalOpenState(true)}
                    >
                        + Bulk Upload
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        className='firstHeaderButton'
                        disabled={!prizes.length}
                        onClick={() => setCopyPrizeEnabled(!copyPrizeEnabled)}
                    >
                        {copyPrizeEnabled ? 'Cancel Copy' : 'Copy Prize'}
                    </Button>
                </Box>

                <TableContainer component={Paper} className={'assignedPrizesTable'}>
                    <Table className='tableHeader' aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                {copyPrizeEnabled && <TableCell align='left'></TableCell>}
                                <TableCell>
                                    <TableSortLabel
                                        active={orderBy === 'priority'}
                                        direction={orderBy === 'priority' ? order : 'asc'}
                                        onClick={() => handleRequestSort('priority')}
                                        IconComponent={() => (
                                            <Box className='sortIcons'>
                                                <ArrowDropUpIcon
                                                    className={
                                                        order === 'asc' && orderBy === 'priority'
                                                            ? 'currentSortingIcon'
                                                            : ''
                                                    }
                                                />{' '}
                                                <ArrowDropDownIcon
                                                    className={
                                                        order !== 'asc' && orderBy === 'priority'
                                                            ? 'currentSortingIcon'
                                                            : ''
                                                    }
                                                />{' '}
                                            </Box>
                                        )}
                                    >
                                        <Typography variant='body2'>Order</Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align='left'>
                                    <TableSortLabel
                                        active={orderBy === 'name'}
                                        direction={orderBy === 'name' ? order : 'asc'}
                                        onClick={() => handleRequestSort('name')}
                                        IconComponent={() => (
                                            <Box className='sortIcons'>
                                                <ArrowDropUpIcon
                                                    className={
                                                        order === 'asc' && orderBy === 'name'
                                                            ? 'currentSortingIcon'
                                                            : ''
                                                    }
                                                />{' '}
                                                <ArrowDropDownIcon
                                                    className={
                                                        order !== 'asc' && orderBy === 'name'
                                                            ? 'currentSortingIcon'
                                                            : ''
                                                    }
                                                />{' '}
                                            </Box>
                                        )}
                                    >
                                        <Typography variant='body2'>Prize Name</Typography>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='body2'>Prize Type</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='body2'>Languages(s)</Typography>
                                </TableCell>
                                {collectAndGetPrizes ? (
                                    <TableCell align='left'>
                                        <Typography variant='body2'>Prize Cost</Typography>
                                    </TableCell>
                                ) : (
                                    <TableCell align='left'>
                                        <TableSortLabel
                                            active={orderBy === 'tier'}
                                            direction={orderBy === 'tier' ? order : 'asc'}
                                            onClick={() => handleRequestSort('tier')}
                                            IconComponent={() => (
                                                <Box className='sortIcons'>
                                                    <ArrowDropUpIcon
                                                        className={
                                                            order === 'asc' && orderBy === 'tier'
                                                                ? 'currentSortingIcon'
                                                                : ''
                                                        }
                                                    />{' '}
                                                    <ArrowDropDownIcon
                                                        className={
                                                            order !== 'asc' && orderBy === 'tier'
                                                                ? 'currentSortingIcon'
                                                                : ''
                                                        }
                                                    />{' '}
                                                </Box>
                                            )}
                                        >
                                            <Typography variant='body2'>Prize Tier</Typography>
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell className=' row-item-assign-promo' align='left'>
                                    <TooltipText
                                        dataTestId='available-prize-tab-tooltip'
                                        styleName='prize-tooltip-text'
                                        text='Available / Total'
                                        textVariant='body2'
                                        tooltip={availableTooltipText}
                                    />
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='body2'>Active ?</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography variant='body2'>Actions</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prizes.length === 0 && (
                                <TableRow key={1} className='addNew'>
                                    <TableCell align='left'>
                                        <Typography variant='body1'>
                                            <AddCircleOutlineOutlinedIcon className='addIcon' />
                                            ADD NEW PRIZE
                                        </Typography>
                                    </TableCell>
                                    {Array(6)
                                        .fill(1)
                                        .map((el, i) => (
                                            <TableCell key={i} align='left'></TableCell>
                                        ))}
                                    <TableCell align='left'>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            className='createPrize'
                                            component={RouterLink}
                                            to={`/addPrize/${config.promotionId}/${config.configurationId}/`}
                                        >
                                            Create
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                            {prizes &&
                                (rowsPerPage > 0
                                    ? prizes
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .sort(getComparator(order, orderBy))
                                    : prizes.sort(getComparator(order, orderBy))
                                ).map((prize: any) => (
                                    <TableRow key={prize.prize_id} className='prizeRow'>
                                        {copyPrizeEnabled &&
                                            <TableCell>
                                                <RadioGroup
                                                    sx={{ position: 'absolute' }}
                                                    aria-labelledby="copy-prize-radio-buttons-group"
                                                    name="copy-prize-radio-buttons-group"
                                                    value={selectedPrizeId}
                                                >
                                                    <FormControlLabel onClick={() => { openCopyPrizeModal(prize) }} value={prize.prize_id} control={<Radio />} label="" />
                                                </RadioGroup>
                                            </TableCell>
                                        }

                                        <TableCell align='left'>
                                            <span className='numIndex'>{prize.priority || 0}</span>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Typography variant='body1' className='prizeName'>
                                                {JSON.parse(prize.name)[defaultLanguage]}
                                            </Typography>
                                            <Typography variant='body2'>
                                                Prize ID:
                                                <span className='greyText'>
                                                    {' '}
                                                    {prize.prize_id}
                                                    <ContentCopyIcon
                                                        className='copyPrizeId'
                                                        onClick={() => copyToClipboard(prize.prize_id)}
                                                    />
                                                </span>
                                            </Typography>
                                            {prize.cost ? (
                                                <Typography variant='body2'>
                                                    Redemption Limit:{' '}
                                                    <span className='greyText'>
                                                        {prize.redemption_limit || <>N/A</>}
                                                    </span>
                                                </Typography>
                                            ) : (
                                                <Typography variant='body2'>
                                                    Winning Limit:{' '}
                                                    <span className='greyText'>
                                                        {(prizeLimits && prizeLimits[prize.prize_id]) || (
                                                            <>N/A</>
                                                        )}
                                                    </span>
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Typography variant='body2' className='greyText'>
                                                {getPrizeType(prize)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            {Object.keys(JSON.parse(prize.desc)).map((key) => (
                                                <Typography
                                                    key={key}
                                                    variant='body2'
                                                    className='greyText'
                                                >
                                                    {key}
                                                </Typography>
                                            ))}
                                        </TableCell>
                                        {prize.cost ? (
                                            !(prize.cost.length === 0) ? (
                                                <TableCell className='greyText' align='left'>
                                                    {processPrizeCost(prize.cost).map(
                                                        (item: string) => (
                                                            <Typography
                                                                key={item}
                                                                variant='body2'
                                                                className='greyText'
                                                            >
                                                                {item}
                                                            </Typography>
                                                        )
                                                    )}
                                                </TableCell>
                                            ) : (
                                                <TableCell className='greyText' align='left'>
                                                    N/A
                                                </TableCell>
                                            )
                                        ) : (
                                            <TableCell className='greyText' align='left'>
                                                {(prize.tier && prize.tier) || 'N/A'}
                                            </TableCell>
                                        )}
                                        <TableCell className='greyText' align='left'>
                                            {(prize.total_available &&
                                                prize.total_available + '/' + prize.total_amount) ||
                                                'N/A'}
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Switch
                                                disabled={copyPrizeEnabled}
                                                className='root'
                                                data-testid={`${prize.prize_id}-updateStatus`}
                                                checked={prize.active}
                                                onClick={() => {
                                                    setConfirmPrizeDeactivate(true);
                                                    setSelectedPrizeId(prize.prize_id);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className='greyText' align='left'>
                                            <IconButton
                                                disabled={copyPrizeEnabled}
                                                component={RouterLink}
                                                to={`/addPrize/${config.promotionId}/${config.configurationId}`}
                                                state={{
                                                    prize: {
                                                        prize_id: prize.prize_id,
                                                        redemption_limit: prize.cost
                                                            ? prize.redemption_limit
                                                            : prizeLimits[prize.prize_id],
                                                        tempParams: {
                                                            start_date:
                                                                config.configurationParameters
                                                                    ?.configurationStartUtc,
                                                            end_date:
                                                                config.configurationParameters
                                                                    ?.configurationEndUtc,
                                                            country:
                                                                config.configurationParameters?.country || '',
                                                            config_timezone:
                                                                config.configurationParameters
                                                                    ?.configurationDatesTimezone,
                                                            winningLimit: prize.cost
                                                                ? !!prize.redemption_limit
                                                                : !!prizeLimits[prize.prize_id],
                                                            mechanic: getMechanic(),
                                                        },
                                                    },
                                                    configData: config,
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                disabled={copyPrizeEnabled} data-testid={`${prize.prize_id}-delete`}
                                                onClick={() => {
                                                    setConfirmDelete(true);
                                                    setSelectedPrizeId(prize.prize_id);
                                                }}>
                                                <ClearIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className='tablePagination'
                    classes={{ menuItem: 'paginationMenu' }}
                    rowsPerPageOptions={[15, 20, 25, { label: 'All', value: -1 }]}
                    component='div'
                    count={prizes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Typography variant='h3'>Helpful Links</Typography>
                <Box className='helpfulLinks'>
                    <Link
                        rel='noopener noreferrer'
                        href={prizesRef.PRIZE_CATALOGUE}
                        color='inherit'
                        target='_blank'
                    >
                        Prize Catalog (Wiki)
                    </Link>
                    <Link href={prizesRef.CREATE_PRIZE} color='inherit'>
                        How to create a prize (Wiki and Video)
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export { Prizes };
