import { SetStateAction } from 'react';
import { Storage } from 'aws-amplify';
import awsconfig from '../../../../aws-exports';
import { appendPublicDomainName } from '../fileUploadHelpers';
import { ProgressState } from '../../../../types/componentTypes/FileUploadTypes';

export const deleteFileFromStorage = async ({
  bucket,
  filePath,
  fileName,
  deleteCallback,
  usePublicDomain,
}) => {
  const opt: any = {};
  if (bucket !== 'private') {
    opt.bucket = awsconfig.aws_user_files_s3_bucket.replace('private', bucket);
  }

  await Storage.remove(`${filePath}/${decodeURI(fileName)}`, opt);

  if (deleteCallback) {
    deleteCallback(
      usePublicDomain
        ? appendPublicDomainName({
            path: `${filePath}/${decodeURI(fileName)}`,
            bucketName: awsconfig.aws_user_files_s3_bucket,
          })
        : fileName
    );
  }
};

export const uploadToStorage = async (
  files: File[],
  updateProgressInfo: (progressFileInfo: any, index?: number) => void,
  bucket: string,
  acl: string,
  filePath: string,
  usePublicDomain: boolean,
  setProgress: React.Dispatch<SetStateAction<ProgressState>>,
  setFile: React.Dispatch<SetStateAction<[]>>
) => {
  const result = await Promise.all(
    files.map((file: File, index: number) => {
      const opt: { [key: string]: string | ((x: any) => void) } = {
        contentType: file.type,
        progressCallback(progressEvent: { [key: string]: any }) {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          const progressFileInfo = { fileName: file.name, percent: progress };
          updateProgressInfo(progressFileInfo, index);
        },
      };

      if (bucket !== 'private') {
        opt.bucket = awsconfig.aws_user_files_s3_bucket.replace(
          'private',
          bucket
        );
        opt.acl = acl;
      }
      const fileName = file.name.includes(' ')
        ? encodeURIComponent(file.name)
        : file.name;

      return Storage.put(`${filePath}/${fileName}`, file, opt);
    })
  )
    .then((res: any) => {
      return res.map(({ key }: any) =>
        usePublicDomain
          ? appendPublicDomainName({
              path: key,
              bucketName: awsconfig.aws_user_files_s3_bucket,
            })
          : key
      );
    })
    .catch((err: ErrorEvent) => {
      console.error('Error uploading file', err);
      setProgress([]);
      setFile([]);
    });

  return result;
};
